:root {
	--color-primary-red: #d8315b;
	--color-primary-orange: #e88832;
	--color-primary-green: #00ada8;
}

body {
	font-family: "Montserrat" !important;
	/* font-style: normal; */
}

.app {
	padding: 1rem;

	& .header {
		display: flex;
		margin-bottom: 1rem;

		& .logo {
			@media (max-width: 768px) {
				width: 8rem;
			}
			@media (max-width: 480px) {
				width: 7rem;
			}
			width: 10rem;
			margin-bottom: 1rem;
		}
	}

	& .content {
		display: flex;
		flex-direction: column;
	}
}

@mixin module() {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 0 1rem 0;
	text-transform: uppercase;
}

@mixin module-img() {
	@media (max-width: 768px) {
		width: 7rem;
		margin-bottom: 1rem;
	}
	@media (max-width: 480px) {
		width: 5rem;
		margin-bottom: 1rem;
	}
	width: 10rem;
	margin-bottom: 1.5rem;
}

@mixin module-title() {
	@media (max-width: 768px) {
		font-size: 2rem;
	}
	@media (max-width: 480px) {
		font-size: 1.5rem;
	}
	font-size: 3.5rem;
}

.content .modules {
	display: flex;
	margin-bottom: 1rem;

	&__learn {
		@include module();

		&-img {
			@include module-img();
		}

		&-title {
			@include module-title();
			color: var(--color-primary-red);
		}
	}
	&__play {
		@include module();
		&-img {
			@include module-img();
		}

		&-title {
			@include module-title();
			color: var(--color-primary-orange);
		}
	}
	&__earn {
		@include module();
		&-img {
			@include module-img();
		}
		&-title {
			@include module-title();
			color: var(--color-primary-green);
		}
	}
}

.comingsoon {
	@media (max-width: 768px) {
		font-size: 2.5rem;
	}
	@media (max-width: 480px) {
		font-size: 2rem;
	}
	line-height: 1.3;
	font-size: 4rem;
	/* font-weight: bold; */
	display: flex;
	justify-content: center;
	margin-top: 1rem;
	text-align: center;
}

.strokeme {
	color: white;
	/* text-transform: uppercase; */
	font-style: italic;
	@media (max-width: 768px) {
		text-shadow: -1.5px -1.5px 0 var(--color-primary-red),
			1.5px -1.5px 0 var(--color-primary-orange),
			-1.5px 1.5px 0 var(--color-primary-green), 1.5px 1.5px 0 white;
	}
	@media (max-width: 480px) {
		text-shadow: -1px -1px 0 var(--color-primary-red),
			1px -1px 0 var(--color-primary-orange),
			-1px 1px 0 var(--color-primary-green), 1px 1px 0 white;
	}
	text-shadow: -2px -2px 0 var(--color-primary-red),
		2px -2px 0 var(--color-primary-orange),
		-2px 2px 0 var(--color-primary-green), 2px 2px 0 white;
}

.hiring {
	display: flex;
	align-items: center;
	flex-direction: column;

	&__heading {
		margin: 3rem 0 1.5rem 0;

		@media (max-width: 480px) {
			font-size: 2rem;
		}
		font-size: 2rem;

		& .hashtag {
			font-weight: bold;
		}

		& .word-1 {
			color: var(--color-primary-red);
		}

		& .word-2 {
			color: var(--color-primary-orange);
		}

		& .word-3 {
			color: var(--color-primary-green);
		}
	}

	&__contactus {
		text-align: center;
		@media (max-width: 768px) {
			font-size: 1.3rem;
			margin-bottom: 1rem;
		}
		@media (max-width: 480px) {
			font-size: 1.2rem;
			margin-bottom: 0.8rem;
		}
		font-size: 1.5rem;
		margin-bottom: 1.5rem;

		&-email {
			font-weight: bolder;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		justify-content: center;

		@media (max-width: 768px) {
			flex-direction: column;
		}
		@media (max-width: 480px) {
			flex-direction: column;
		}
		justify-content: space-around;
	}

	&__content > * {
		padding: 1rem;
		margin: 1rem;
		box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.3);
		border-radius: 1rem;
		flex: 1;
	}

	&__nodejs {
		color: white;
		background: var(--color-primary-red);

		&__heading {
			display: flex;
			justify-content: center;
		}
	}

	&__uiux {
		color: white;
		background: var(--color-primary-orange);

		&__heading {
			display: flex;
			justify-content: center;
		}
	}

	&__flutter {
		color: white;
		background: var(--color-primary-green);

		&__heading {
			display: flex;
			justify-content: center;
		}
	}
}

.spacer {
	@media (max-width: 768px) {
		height: 0.6rem;
	}
	@media (max-width: 480px) {
		height: 0.3rem;
	}
}

#privacy-component,
#terms-condition-component {
	padding: 2rem;
	display: flex;
	flex-direction: column;

	& #header-container {
		margin-bottom: 3.5rem;
		& .heading {
			display: flex;
			justify-content: center;
			align-items: center;
			font-weight: bold;
			font-size: 2.4rem;
			margin-bottom: 1.5rem;
		}
	}

	& #section-container {
		margin-bottom: 2rem;

		& .section {
			margin-bottom: 3rem;
		}
		& .section-heading {
			font-size: 1.5rem;
			font-weight: bold;
			text-decoration: underline;
			margin: 10px 0;
		}

		& #contact-us.section {
			// justify-content: start;

			& .content {
				align-items: flex-start;
			}
			// justify-content: start;
			// flex-direction: column;
			// justify-content: start;
			// align-content: start;
			&__address {
				// align-self: start;
				// justify-self: unset;
				// display: flex;
				flex-direction: column;
				margin-bottom: 1rem;
			}
		}
	}

	& .content {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.2rem;
		// margin: 0.5rem;
	}
}

.text-bold {
	font-weight: bold;
}
